<template>
  <div class="container">
    <div id="contentPage">
      <h1>
        {{ title[lang] }}
      </h1>

      <div id="contentTopDiv">
        <featured-box
          v-if="Object.keys(featuredItem).length"
          :url="resolveUrl(featuredItem)"
          :img="resolveBgmg(featuredItem)"
          :disabled="['tag']"
        >
          <template v-slot:title>
            {{ resolveTitle(featuredItem) }}
          </template>

          <template v-slot:description>
            {{ resolveDate(featuredItem) }}
          </template>
        </featured-box>

        <medium-box
          v-for="(mainItem, key) in mainItems"
          :key="key"
          :url="resolveUrl(mainItem)"
          :img="resolveBgmg(mainItem)"
          :disabled="['tag']"
          :class="{ last: (key + 1) % 2 === 0 }"
        >
          <template v-slot:title>
            {{ resolveTitle(mainItem) }}
          </template>

          <template v-slot:description>
            {{ resolveDate(mainItem) }}
          </template>
        </medium-box>
      </div>

      <div class="NewsDiv20">
        <medium-box
          v-for="(sideItem, key) in sideItems"
          :key="key"
          :url="resolveUrl(sideItem)"
          :img="resolveBgmg(sideItem)"
          :disabled="['tag']"
        >
          <template v-slot:title>
            {{ resolveTitle(sideItem) }}
          </template>

          <template v-slot:description>
            {{ resolveDate(sideItem) }}
          </template>
        </medium-box>

        <infinite-loading v-if="sideItems.length" @infinite="requestPlaylist">
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import LangMixin from "../mixins/LangMixin";
import FeaturedBox from "../components/FeaturedBox";
import MediumBox from "../components/MediumBox";
import CollectionMixin from "../mixins/CollectionMixin";
import eventBus from "../eventBus";
import InfiniteLoading from "vue-infinite-loading";
import ArticleMixin from "../mixins/ArticleMixin";

export default {
  components: {
    FeaturedBox,
    MediumBox,
    InfiniteLoading,
  },

  mixins: [LangMixin, CollectionMixin, ArticleMixin],

  mounted() {
    this.initData();
    this.requestPlaylist();

    eventBus.$on("langChange", ({ lang, history }) => {
      if (!history) {
        this.$router.push(this.url[lang]);
      }
    });
  },

  data() {
    return {
      title: {
        lat: "",
        cir: "",
      },
      url: {
        lat: "",
        cir: "",
      },
    };
  },

  methods: {
    resolveUrl(item) {
      if (item.Url) {
        return `${this.$route.path}/${item.Url}`;
      } else {
        return `${this.$route.path}`;
      }
    },

    resolveBgmg(item) {
      return { backgroundImage: `url('${item.MainImage}')` };
    },

    resolveTitle(item) {
      return item[`Title${this.lang.toUpperCase()}`];
    },

    resolveDate(item) {
      return this.getFormatedDate(item.DTPublished);
    },

    setSideItemData({ data, nextpg }) {
      this.sideItems.push(...data);
      this.pagination.nextPage = nextpg;
    },

    requestPlaylist($state) {
      let url = `relist?url=${this.$route.params.slug}&lng=${this.lang}&sr=${this.pagination.nextPage}`;

      this.requestData(
        url,
        (data) => {
          if (!data.error) {
            if (this.firstPage) {
              this.featuredItem = data.data[0];
              this.mainItems.push(...data.data.slice(1, 5));
              data.data = data.data.slice(5);
              this.$emit("pageLoaded");
            }

            this.setPageData(data);
            this.setSideItemData(data);
          } else {
            this.$router.push({ name: "not_found" });
          }

          this.loadMore = !data.error && data.nextpg !== -1;
        },
        $state
      );
    },

    setPageData({ category, categoryc, url, urlc }) {
      this.title = {
        lat: category,
        cir: categoryc,
      };
      this.url = {
        lat: url,
        cir: urlc,
      };
    },
  },
};
</script>